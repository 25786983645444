import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);
function redirectToStore() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Redirect to App Store for iOS devices
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location = 'https://apps.apple.com/pk/app/dau-vault-mobile-wallet/id6468998385';
  } 
  // Redirect to Google Play for Android devices
  else if (/android/i.test(userAgent)) {
      window.location = 'https://play.google.com/store/apps/details?id=com.dau.vault';
  }
  // Optionally, redirect to a fallback page for other devices or if detection fails
  else {
      window.location = 'https://dau-assets.s3.eu-west-2.amazonaws.com/DAU-Vault.apk';
  }
}
redirectToStore();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
